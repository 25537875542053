import React, { useEffect, useState } from "react";
import UserBillingMilestoneProgressItem from "./UserBillingMilestoneProgressItem";

function UserBillingMilestoneProgress({ milestones, userAmount }) {
  const [count, setCount] = useState(0);
  const [amountMilestones, setAmountMilestones] = useState([]);

  useEffect(() => {
    setCount(milestones.length);
    for (let i = 0; i < milestones.length; i++) {
      setAmountMilestones((prev) => {
        let updated = [...prev];
        updated[i] = userAmount >= milestones[i].targetAmount;
        return updated;
      });
    }
  }, [milestones, userAmount]);

  return (
    <div className="bg-purple-50 p-2 rounded-md">
      <div className="flex flex-row gap-0">
        {milestones.map((m, i) => (
          <div className={`flex-1 flex-grow h-auto py-8`} key={`${i}`}>
            <UserBillingMilestoneProgressItem
              milestone={m}
              active={amountMilestones[i]}
              nextActive={amountMilestones[i + 1]}
              isLast={i === count - 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserBillingMilestoneProgress;
