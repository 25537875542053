import React, { useEffect, useState } from "react";
import LoadingIcon from "../../assets/LoadingIcon";

import { isEmpty } from "../../helpers/validator";
import {
  saveInvoiceMilestone,
  updateInvoiceMilestone,
} from "../../services/invoice-milestone";

function AddInvoiceMilestone({ milestone, onDismiss }) {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {
    if (milestone !== null) {
      setAmount(`${milestone.targetAmount}`);
      setDescription(milestone.description);
    }
  }, [milestone]);

  const handleSave = async () => {
    setErrorMessage(null);

    let errors = [];
    if (isEmpty(amount)) {
      errors.push("Please enter the amount.");
    }
    if (isEmpty(description)) {
      errors.push("Please enter the description.");
    }

    if (errors.length > 0) {
      setErrorMessage(errors);
      return;
    }

    setLoading(true);
    try {
      const params = { targetAmount: amount, description: description };
      setLoading(true);

      try {
        let response;

        if (milestone === null) {
          response = await saveInvoiceMilestone(params);
        } else {
          response = await updateInvoiceMilestone({
            id: milestone.id,
            ...params,
          });
        }

        //console.log(response);
        onDismiss(response);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage([err.message]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex-auto p-4 py-8 mt-8 bg-purple-50">
        <form>
          {errorMessage !== null && errorMessage.length > 0 ? (
            <div className="bg-red-100 text-red-700 text-sm p-4 mx-4 mb-8 border-red-400 border-[0.5px] rounded-sm">
              <ul>
                {errorMessage.map((msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}

          <div className="flex flex-wrap">
            <div className="w-full px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Amount<span>*</span>
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={amount || ""}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full px-4 mb-2">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Description<span>*</span>
                </label>
                <textarea
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ""}
                ></textarea>
              </div>
            </div>
            <div className="w-full px-4 mb-2 mt-4 flex-1 justify-end">
              <div className="relative w-full mb-3">
                <button
                  className="flex gap-1 items-center bg-lightBlue-500 text-white bg-green-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none disabled:bg-gray-400 mr-1 ease-linear transition-all duration-150"
                  type="button"
                  disabled={loading}
                  onClick={handleSave}
                >
                  {loading ? <LoadingIcon /> : null}
                  <span>{`${milestone === null ? "Save" : "Update"}`}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddInvoiceMilestone;
