import React from "react";

function MilestoneTrackTrailingView({ active, lastItem }) {
  return (
    <div
      className={`w-[50%] h-1 z-1 text-left ${
        lastItem ? "bg-transparent" : active ? "bg-purple-500" : "bg-purple-300"
      }`}
    ></div>
  );
}

export default MilestoneTrackTrailingView;
