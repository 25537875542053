import React from "react";
import { formatAppDate } from "../../helpers/date-formatter";
import { Link } from "react-router-dom";

function UserBillingInvoiceSummaryTable({ data }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {["txn id", "amount (€)", "transaction date", "coupon"].map(
              (el, id) => (
                <th
                  key={el}
                  className={`border-y bg-slate-50 border-slate-50 py-3 px-5 ${
                    id == 0 ? "text-left" : "text-center"
                  }`}
                >
                  <span className="text-[11px] font-bold uppercase text-slate-400">
                    {el}
                  </span>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem) => {
            const {
              id,
              transactionId,
              currency,
              totalAmount,
              couponCode,
              transactionDate,
            } = dataItem;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[100px]">
                  <div className="flex flex-col gap-2">
                    <span className="text-left text-xs font-semibold text-blue-gray-600 underline hover:text-blue-500">
                      <Link to={`/invoice/${id}`}>{transactionId}</Link>
                    </span>
                  </div>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {currency}
                    {totalAmount}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {formatAppDate(transactionDate)}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {couponCode === null ? "-" : couponCode}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default UserBillingInvoiceSummaryTable;
