import {
  ArrowsUpDownIcon,
  BellAlertIcon,
  DocumentTextIcon,
  HomeIcon,
  MegaphoneIcon,
  NewspaperIcon,
  PowerIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  ShoppingCartIcon,
  StopCircleIcon,
  TrophyIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const navItemList = [
  {
    id: 1,
    title: "Dashboard",
    destination: "/",
    Icon: <HomeIcon className="w-4 h-4" />,
  },
  {
    id: 2,
    title: "Offer Products",
    destination: "#",
    Icon: <ShoppingCartIcon className="w-4 h-4" />,
    children: [
      {
        id: 2,
        title: "List Products",
        destination: "/offer-products",
        Icon: <ShoppingCartIcon className="w-4 h-4" />,
      },
      {
        id: 1,
        title: "Sort Products",
        destination: "/offer-products/sort-order",
        Icon: <ArrowsUpDownIcon className="w-4 h-4" />,
      },
    ],
  },
  {
    id: 3,
    title: "News",
    destination: "/news",
    Icon: <NewspaperIcon className="w-4 h-4" />,
  },
  {
    id: 7,
    title: "Promotions",
    destination: "#",
    Icon: <RocketLaunchIcon className="w-4 h-4" />,
    children: [
      {
        id: 1,
        title: "Campaigns",
        destination: "/coupon-campaign",
        Icon: <MegaphoneIcon className="w-4 h-4" />,
      },
    ],
  },
  {
    id: 4,
    title: "Users",
    destination: "/users",
    Icon: <UsersIcon className="w-4 h-4" />,
  },
  {
    id: 50,
    title: "Invoice Milestones",
    destination: "#",
    Icon: <TrophyIcon className="w-4 h-4" />,
    children: [
      {
        id: 501,
        title: "Milestones",
        destination: "/invoice-milestone",
        Icon: <StopCircleIcon className="w-4 h-4" />,
      },
      {
        id: 502,
        title: "Invoices",
        destination: "/invoice",
        Icon: <DocumentTextIcon className="w-4 h-4" />,
      },
    ],
  },
  {
    id: 10,
    title: "Banners",
    destination: "#",
    Icon: <RectangleStackIcon className="w-4 h-4" />,
    children: [
      {
        id: 8,
        title: "Sliding Banner",
        destination: "/slider",
        Icon: <RectangleStackIcon className="w-4 h-4" />,
      },
      {
        id: 9,
        title: "Promo Banner",
        destination: "/promo-banner",
        Icon: <RectangleStackIcon className="w-4 h-4" />,
      },
    ],
  },
  {
    id: 6,
    title: "Push Notification",
    destination: "/push-notification",
    Icon: <BellAlertIcon className="w-4 h-4" />,
  },
  {
    id: 5,
    title: "Logout",
    destination: "/logout",
    Icon: <PowerIcon className="w-4 h-4" />,
  },
];
