import React from "react";
import { TrophyIcon } from "@heroicons/react/16/solid";

function TrophyView({ active }) {
  return (
    <div className="w-full flex justify-center">
      <TrophyIcon
        className={`w-6 h-6 mb-0 ${
          active ? "text-yellow-400" : "text-gray-300"
        }`}
      />
    </div>
  );
}

export default TrophyView;
