import React, { useState } from "react";
import {
  MagnifyingGlassCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

function SearchField({ onSearch, onClear, placeholder }) {
  const [searchText, setSearchText] = useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = () => {
    onSearch(searchText);
  };

  const clearSearch = () => {
    setSearchText("");
    onClear();
  };

  return (
    <div className="flex py-1 px-1 w-full items-stretch border-b-2">
      <form onSubmit={handleSearchSubmit} className="flex w-full">
        <input
          type="text"
          name="searchText"
          placeholder={placeholder === null ? "Search" : placeholder}
          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none w-full ease-linear transition-all duration-150"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchText !== "" ? (
          <button
            className="text-gray-300 hover:text-gray-400 font-bold uppercase text-xs px-2 py-2 rounded outline-none focus:outline-none disabled:bg-gray-400 ease-linear transition-all duration-150"
            type="button"
            onClick={clearSearch}
          >
            <XMarkIcon className="w-4 h-4 rounded-full border-gray-300 hover:text-gray-400 border-[1px]" />
          </button>
        ) : null}
        <button
          className="text-green-700 hover:text-green-900 font-bold uppercase text-xs px-2 py-2 rounded outline-none focus:outline-none disabled:bg-gray-400 ease-linear transition-all duration-150"
          type="button"
          onClick={handleSearch}
        >
          <MagnifyingGlassCircleIcon className="w-6 h-6" />
        </button>
      </form>
    </div>
  );
}

export default SearchField;
