import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getUserDetails } from "../../../services/users";
import { formatAppDate } from "../../../helpers/date-formatter";
import {
  getUserInvoiceAmount,
  getUserInvoices,
} from "../../../services/billing-invoice";
import UserBillingInvoiceSummaryTable from "../../../components/users/UserBillingInvoiceSummaryTable";
import UserBillingMilestoneProgress from "../../../components/users/UserBillingMilestoneProgress";
import { getInvoiceMilestones } from "../../../services/invoice-milestone";

function UserDetails() {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(null);
  const [billingMilestones, setBillingMilestones] = useState([]);

  const [userInvoices, setUserInvoices] = useState(null);
  const params = useParams();

  const { id: userId } = params;

  useEffect(() => {
    fetchUserDetails();
  }, [params]);

  const fetchUserDetails = async () => {
    try {
      const response = await getUserDetails(userId);
      setUser(response);
      setUserInfo(response.userInfo);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await getUserInvoices(userId, "transactionDate", "DESC");
      setUserInvoices(response.data);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await getUserInvoiceAmount(userId);
      setTotalInvoiceAmount(response.total);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await getInvoiceMilestones();
      let sortedMilestones = response.sort(
        (m1, m2) => m1.targetAmount - m2.targetAmount
      );
      setBillingMilestones(sortedMilestones);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {user === null ? (
        <p className="text-xs text-red-600 uppercase">User not found.</p>
      ) : (
        <>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white p-2">
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                  <div className="flex flex-col flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                      <div>
                        <div className="rounded-t mb-4 py-3 border-0">
                          <div className="relative w-full max-w-full flex-grow flex-1">
                            <h2 className="text-xl font-semibold">
                              User Details
                            </h2>
                          </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                          <table className="items-center w-full bg-transparent border-collapse">
                            <tbody>
                              <tr>
                                <th className="w-[200px] order-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Username
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {`${user.username}`}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Fullname
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {[userInfo.firstName, userInfo.lastName].join(
                                    " "
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Email
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {userInfo.email}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Phone
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {userInfo.phoneNumber}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Registered On
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(userInfo.registeredOn)}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-top border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  QR Code
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <img
                                    src={userInfo.qrcodeUrl}
                                    className="w-32 h-32"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-top border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Invoice Amount (this month)
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs font-bold whitespace-nowrap p-4">
                                  €
                                  {totalInvoiceAmount !== null
                                    ? `${totalInvoiceAmount}`
                                    : "0"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4 my-6">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                  <div className="flex flex-col flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                      <div className="my-8">
                        <div className="rounded-t mb-4 py-3 border-0">
                          <div className="relative w-full max-w-full flex-grow flex-1">
                            <h2 className="text-xl font-semibold">
                              Billing Milestones
                            </h2>
                          </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                          <UserBillingMilestoneProgress
                            milestones={billingMilestones}
                            userAmount={totalInvoiceAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                  <div className="flex flex-col flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                      <div>
                        <div className="rounded-t mb-4 py-3 border-0">
                          <div className="relative w-full max-w-full flex-grow flex-1">
                            <h2 className="text-xl font-semibold">
                              Recent Invoices
                            </h2>
                          </div>
                        </div>
                        {userInvoices !== null ? (
                          <div className="block w-full overflow-x-auto pb-8">
                            <UserBillingInvoiceSummaryTable
                              data={userInvoices}
                            />

                            <div className="flex mt-16 justify-end">
                              <Link to={`/invoice?user=${user.username}`}>
                                <span className="bg-green-700 text-white  active:bg-green-600 font-bold uppercase text-xs px-6 py-2 rounded shadow hover:bg-green-800 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
                                  View All
                                </span>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>Invoices not found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UserDetails;
