import { getRequest } from "../api";

const path = "admin/ext-billing/invoice";

export const getInvoices = async (page, user) => {
  if (isNaN(page)) {
    page = 0;
  }

  page = isNaN(page) ? 0 : page - 1;
  if (page < 0) page = 0;

  let userSearch = "";
  if (user !== null) {
    userSearch = `&user=${user}`;
  }

  try {
    const response = await getRequest(
      `${path}?page=${page}&pageSize=50${userSearch}`,
      true
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getInvoiceItem = async (id) => {
  try {
    const response = await getRequest(`${path}/${id}`, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUserInvoices = async (
  userId,
  sortBy = null,
  sortOrder = null
) => {
  try {
    const sorting = sortBy !== null ? `sortBy=${sortBy}` : "";
    const ordering = sortOrder !== null ? `sort=${sortOrder}` : "";

    let params = [sorting, ordering].join("&");

    if (params !== "" && params !== "&") {
      params = `?${params}`;
    }

    const response = await getRequest(`${path}/user/${userId}${params}`, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUserInvoiceAmount = async (userId) => {
  try {
    const response = await getRequest(
      `${path}/user/${userId}/invoice-amount`,
      true
    );
    return response;
  } catch (err) {
    throw err;
  }
};
