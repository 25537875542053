import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import SectionTitle from "../../../components/section/SectionTitle";
import SectionHeader from "../../../components/section/SectionHeader";
import PaginatedView from "../../../components/pagination/PaginatedView";
import { getInvoices } from "../../../services/billing-invoice";
import BillingInvoiceTable from "../../../components/billing-invoice/BillingInvoiceTable";
import { paginationOffset } from "../../../helpers/pagination";

import {
  XMarkIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import SearchField from "../../../components/search/SearchField";

const path = "invoice";

function BillingInvoice() {
  const [invoices, setInvoices] = useState([]);
  const [invoiceResponse, setInvoiceResponse] = useState(null);

  const [searchText, setSearchText] = useState("");

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { page: pageNum } = params;

  useEffect(() => {
    const user = searchParams.get("user");
    fetchInvoices(user);
  }, [params, searchParams]);

  const fetchInvoices = async (user = null) => {
    try {
      const response = await getInvoices(pageNum, user);
      setInvoiceResponse(response);
      setInvoices(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = (searchText) => {
    navigate(`/${path}?user=${encodeURIComponent(searchText)}`, {
      replace: true,
    });
  };

  const handleClearSearch = () => {
    navigate(`/${path}`, { replace: true });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <div className="flex flex-col lg:flex-row justify-end gap-2">
            <div className="flex w-full lg:w-3/5">
              <SectionHeader>
                <SectionTitle title={"Invoices"} />
              </SectionHeader>
            </div>

            <div className="flex w-full lg:w-2/5">
              <SearchField
                onSearch={handleSearch}
                onClear={handleClearSearch}
                placeholder={"Search by name"}
              />
            </div>
          </div>
        </div>

        <BillingInvoiceTable
          data={invoices}
          offset={paginationOffset(invoiceResponse?.metadata)}
          path={path}
        />

        {invoiceResponse?.metadata?.totalPages > 1 ? (
          <div className="py-2 flex justify-end my-6">
            <PaginatedView
              path={`/${path}/page`}
              total={invoiceResponse?.metadata.totalPages}
              current={invoiceResponse?.metadata?.currentPage}
              next={invoiceResponse?.metadata?.nextPage}
              previous={invoiceResponse?.metadata?.previousPage}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default BillingInvoice;
