import React from "react";
import { CheckIcon } from "@heroicons/react/16/solid";

function MilestoneKnobView({ active }) {
  return (
    <div
      className={`p-2 rounded-full justify-center items-center flex ${
        active ? "bg-green-700" : "bg-gray-300"
      } `}
    >
      <CheckIcon
        className={`w-4 h-4 ${active ? "text-white" : "text-gray-400"}`}
      />
    </div>
  );
}

export default MilestoneKnobView;
