export const isCreateInRoute = (location, path) => {
  if (
    location.pathname.indexOf(`/${path}`) !== -1 &&
    location.pathname.indexOf("/create") !== -1
  ) {
    return true;
  }
  return false;
};

export const isEditInRoute = (location, path) => {
  if (
    location.pathname.indexOf(`/${path}`) !== -1 &&
    location.pathname.indexOf("/edit") !== -1
  ) {
    return true;
  }
  return false;
};

export const isDeleteInRoute = (location, path) => {
  if (
    location.pathname.indexOf(`/${path}`) !== -1 &&
    location.pathname.indexOf("/delete") !== -1
  ) {
    return true;
  }
  return false;
};
