import { getToken } from "../context/auth/auth-storage";
import {
  NetworkError,
  NotAuthorizedError,
  BadRequestError,
} from "./network-error";

const headers = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

const URL_ENV = {
  local: "http://localhost:8080",
  staging: "https://api.demo.solu.loyaltyapp.fi",
  production: "https://api.solu.loyaltyapp.fi",
};

const baseURL = URL_ENV.production;

export const postRequest = async (path, requestData, auth = false) => {
  if (auth) {
    const token = getToken();
    headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete headers["Authorization"];
  }

  try {
    const url = `${baseURL}/${path}`;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(requestData),
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = await response.json();
      return json;
    } else {
      const json = await response.json();
      errorResponseParser(json);
    }
  } catch (err) {
    throw err;
  }
};

export const putRequest = async (path, requestData, auth = false) => {
  if (auth) {
    const token = getToken();
    headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete headers["Authorization"];
  }

  try {
    const url = `${baseURL}/${path}`;

    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(requestData),
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = await response.json();
      return json;
    } else {
      const json = await response.json();
      errorResponseParser(json);
    }
  } catch (err) {
    throw err;
  }
};

export const getRequest = async (path, auth = false, download = false) => {
  if (auth) {
    const token = getToken();
    headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete headers["Authorization"];
  }

  try {
    const url = `${baseURL}/${path}`;

    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const result = download ? await response.blob() : await response.json();
      return result;
    } else {
      const json = await response.json();
      errorResponseParser(json, statusCode);
      //throw new NotAuthorizedError("Not Authorized");
    }
  } catch (err) {
    throw err;
  }
};

export const uploadRequest = async (path, data, isEdit = false) => {
  const token = getToken();

  //try {
  const url = `${baseURL}/${path}`;

  const response = await fetch(url, {
    method: isEdit ? "PUT" : "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const statusCode = response.status;

  if (statusCode >= 200 && statusCode < 300) {
    const json = await response.json();

    return json;
  } else {
    const json = await response.json();
    errorResponseParser(json);
  }
  // } catch (err) {
  //   console.log("throw from here...");
  //   throw err;
  // }
};

export const deleteRequest = async (path, id) => {
  const token = getToken();
  headers["Authorization"] = `Bearer ${token}`;

  try {
    const url = `${baseURL}/${path}/${id}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = await response.json();
      return json;
    } else {
      const json = await response.json();
      errorResponseParser(json);
    }
  } catch (err) {
    throw err;
  }
};

export const patchRequest = async (path, requestData) => {
  const token = getToken();
  headers["Authorization"] = `Bearer ${token}`;

  try {
    const url = `${baseURL}/${path}`;

    const response = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(requestData),
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = await response.json();
      return json;
    } else {
      const json = await response.json();
      errorResponseParser(json);
    }
  } catch (err) {
    throw err;
  }
};

const errorResponseParser = (response, statusCode) => {
  const message = response.message;
  if (statusCode === undefined) {
    statusCode = response.status;
  }
  if (statusCode === 401 || statusCode === 403) {
    throw new NotAuthorizedError(message, statusCode);
  } else if (statusCode === 400) {
    if (message !== undefined) {
      throw new BadRequestError(message);
    } else {
      const errorMessages = response.errors;
      throw new NetworkError(errorMessages, statusCode);
    }
  } else {
    throw new NetworkError(
      message ?? "Unable to process your request",
      statusCode
    );
  }
};

export default NotAuthorizedError;
