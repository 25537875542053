import { getRequest, postRequest, deleteRequest, putRequest } from "../api";

const path = `admin/offer-milestone`;

export const getInvoiceMilestones = async (page) => {
  if (isNaN(page)) {
    page = 0;
  }

  page = isNaN(page) ? 0 : page - 1;
  if (page < 0) page = 0;

  try {
    const response = await getRequest(`${path}?page=${page}`, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getInvoiceMilestone = async (id) => {
  try {
    const milestone = await getRequest(`${path}/${id}`, true);
    return milestone;
  } catch (err) {
    throw err;
  }
};

export const saveInvoiceMilestone = async (params) => {
  try {
    const response = await postRequest(path, params, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateInvoiceMilestone = async (params) => {
  try {
    const response = await putRequest(path, params, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteInvoiceMilestone = async (id) => {
  try {
    await deleteRequest(path, id);
  } catch (err) {
    throw err;
  }
};
