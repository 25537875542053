import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import Menu from "../components/menu/Menu";
import Navbar from "../components/navbar/Navbar";
import { jwtDecode } from "jwt-decode";

const AppLayout = () => {
  const { token, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null || token === undefined) {
      navigate("/login");
    } else {
      const { exp } = jwtDecode(token);
      const currentTimestamp = Date.now() / 1000;
      if (exp < currentTimestamp) {
        logoutUser();
      }
    }
  });

  return (
    <div className="flex w-full">
      <Menu />

      <div className="grow">
        <Navbar />

        <div className="p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
