import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { formatAppDate } from "../../../helpers/date-formatter";
import { getInvoiceItem } from "../../../services/billing-invoice";
import { DocumentIcon } from "@heroicons/react/24/outline";

const isImage = (url) => {
  let ext = url.split(".").pop();
  const imageExts = ["jpg", "jpeg", "png", "gif"];
  return imageExts.includes(ext);
};

function BillingInvoiceDetails() {
  const [invoice, setInvoice] = useState(null);

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    (async () => {
      try {
        const response = await getInvoiceItem(id);
        setInvoice(response);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id]);

  return (
    <>
      <div className="relative flex flex-col xl:flex-row min-w-0 break-words w-full mb-6 bg-white p-2">
        <div className="w-full xl:w-1/2 px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
              <div className="flex flex-col flex-wrap items-center">
                <div className="relative w-full max-w-full flex-grow flex-1">
                  <div>
                    {invoice === null ? (
                      <span>Invoice not found.</span>
                    ) : (
                      <>
                        <div className="rounded-t mb-4 py-3 border-0">
                          <div className="relative w-full max-w-full flex-grow flex-1">
                            <h2 className="text-xl font-semibold">
                              {`Invoice #${invoice.id}`}
                            </h2>
                          </div>
                        </div>
                        <div className="block w-full overflow-x-auto">
                          <table className="items-center w-full bg-transparent border-collapse">
                            <tbody>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Transaction ID
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {invoice.transactionId}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Transaction Date
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {formatAppDate(invoice.transactionDate)}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Total Amount
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {[invoice.currency, invoice.totalAmount].join(
                                    " "
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Coupon Code
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {invoice.couponCode ?? "-"}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  User ID
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {invoice.user.id}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  User
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  {[
                                    invoice.user.userInfo.firstName,
                                    invoice.user.userInfo.lastName,
                                  ].join(" ")}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  Username
                                </th>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs cursor-pointer text-blue-500 whitespace-nowrap p-4">
                                  <Link to={`/users/${invoice.user.id}`}>
                                    {invoice.user.username}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  {invoice !== null &&
                                  !isImage(invoice.invoiceImageUrl) ? (
                                    <Link
                                      to={invoice?.invoiceImageUrl}
                                      target="_blank"
                                      className="text-white text-sm font-bold"
                                    >
                                      <div className="bg-green-600 flex my-6 max-w-[160px] px-4 py-2 rounded-md justify-start items-center flex-row gap-4">
                                        <div title="coupons">
                                          <DocumentIcon className="w-6 h-6" />
                                        </div>
                                        <div>View Invoice</div>
                                      </div>
                                    </Link>
                                  ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 flex-auto">
              <div className="relative h-350-px"></div>
            </div>
          </div>
        </div>
        {invoice !== null && isImage(invoice.invoiceImageUrl) ? (
          <div className="w-full xl:w-1/2 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-slate-100">
              <img src={`${invoice.invoiceImageUrl}`} className="p-4" />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default BillingInvoiceDetails;
