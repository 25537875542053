import React from "react";

function MilestoneInfoView({ milestone }) {
  return (
    <div className="w-full text-center px-4 mt-2">
      <div>
        <h3 className="text-xs font-bold">€ {milestone.targetAmount}</h3>
        <p className="text-xs w-full">{milestone.description}</p>
      </div>
    </div>
  );
}

export default MilestoneInfoView;
