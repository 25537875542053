import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import SectionTitle from "../../../components/section/SectionTitle";
import SectionHeader from "../../../components/section/SectionHeader";
import FormModal from "../../../components/modal/FormModal";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import AddInvoiceMilestone from "../../../components/offer-milestone/AddInvoiceMilestone";

import {
  isCreateInRoute,
  isEditInRoute,
  isDeleteInRoute,
} from "../../../helpers/route-util";
import InvoiceMilestoneTable from "../../../components/offer-milestone/InvoiceMilestoneTable";
import {
  deleteInvoiceMilestone,
  getInvoiceMilestone,
  getInvoiceMilestones,
} from "../../../services/invoice-milestone";

const path = "invoice-milestone";

function InvoiceMilestone() {
  const [inoviceMilestones, setInvoiceMilestones] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [milestoneToEdit, setMilestoneToEdit] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [milestoneIdToDelete, setMilestoneIdToDelete] = useState(null);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { id, page: pageNum } = params;

  useEffect(() => {
    (async () => {
      try {
        const response = await getInvoiceMilestones();
        setInvoiceMilestones(response);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [params]);

  useEffect(() => {
    if (isCreateInRoute(location, path)) {
      setShowAddForm(true);
      setMilestoneToEdit(null);
    }

    if (isEditInRoute(location, path)) {
      handleShowEdit();
    }

    if (isDeleteInRoute(location, path)) {
      setMilestoneIdToDelete(id);
      setShowDeleteConfirmation(true);
    }
  }, [location]);

  const dismissAddForm = () => {
    setShowAddForm(false);
    setMilestoneToEdit(null);
    setMilestoneIdToDelete(null);
    navigate(`/${path}`, { replace: true });
  };

  const handleShowEdit = async () => {
    try {
      const milestone = await getInvoiceMilestone(id);
      setMilestoneToEdit(milestone);
      setShowAddForm(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMilestoneDelete = async () => {
    try {
      await deleteInvoiceMilestone(milestoneIdToDelete);
    } catch (err) {
      console.log(err);
    } finally {
      setShowDeleteConfirmation(false);
      navigate(-1);
    }
  };

  const handleConfirmationDismiss = () => {
    setShowDeleteConfirmation(false);
    navigate(-1);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Invoice Milestones"} />

            <Link to={"/invoice-milestone/create"}>
              <span className="bg-green-700 text-white  active:bg-green-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:bg-green-800 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
                Add New
              </span>
            </Link>
          </SectionHeader>
        </div>

        <InvoiceMilestoneTable
          offset={0}
          data={inoviceMilestones}
          path={path}
        />
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${
          milestoneToEdit === null ? "Add" : "Update"
        } Invoice Milestone`}
        onDismiss={dismissAddForm}
      >
        <AddInvoiceMilestone
          milestone={milestoneToEdit}
          onDismiss={dismissAddForm}
        />
      </FormModal>

      {milestoneIdToDelete !== null ? (
        <ConfirmDialog
          title="Delete milestone?"
          message={`Are your sure you want to delete the milestone?`}
          visible={showDeleteConfirmation}
          onAccept={handleMilestoneDelete}
          onDismiss={handleConfirmationDismiss}
        />
      ) : null}
    </>
  );
}

export default InvoiceMilestone;
