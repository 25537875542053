import { getRequest } from "./api";

const path = "admin/user";

export const getUsers = async (page, pageSize, searchText) => {
  if (searchText && searchText !== "") {
    path = `${path}/search`;
  }
  path = `${path}?page=${page}&pageSize=${pageSize}&sortBy=username`;
  if (searchText && searchText !== "") {
    path = `${path}&text=${searchText}`;
  }

  const response = await getRequest(path, true);
  return response;
};

export const downloadUsers = async () => {
  const response = await getRequest(`${path}/export`, true, true);
  return response;
};

export const getUserDetails = async (id) => {
  try {
    const response = await getRequest(`${path}/${id}`, true);
    return response;
  } catch (err) {
    throw err;
  }
};
