import React from "react";
import TrophyView from "./milestone/TrophyView";
import MilestoneInfoView from "./milestone/MilestoneInfoView";
import MilestoneTrackLeadingView from "./milestone/MilestoneTrackLeadingView";
import MilestoneTrackTrailingView from "./milestone/MilestoneTrackTrailingView";
import MilestoneKnobView from "./milestone/MilestoneKnobView";

function UserBillingMilestoneProgressItem({
  active,
  nextActive,
  isLast,
  milestone,
}) {
  return (
    <>
      <TrophyView active={active} />

      <div className="flex items-center w-full h-12 justify-center">
        <MilestoneTrackLeadingView active={active} />

        <MilestoneKnobView active={active} />

        <MilestoneTrackTrailingView active={nextActive} lastItem={isLast} />
      </div>
      <MilestoneInfoView milestone={milestone} />
    </>
  );
}

export default UserBillingMilestoneProgressItem;
